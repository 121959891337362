import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { D, M, DesktopFlex, MobileFlex } from '../breakpoints'
import WhiteLogo from '../../static/img/hubtype_logo_white.svg'
import WhiteLogoLittle from '../../static/img/whatsapp/hubtype_logo_white_little.svg'
import BestStartupLogo from '../../static/img/whatsapp/best_startup.svg'
import WhatsappLogo from '../../static/img/whatsapp/whatsapp_banner_logo.svg'
import DoubleCheckIcon from '../../static/img/whatsapp/double_check.svg'

import { useLandingData } from '../../hooks'
import Section from '../Section'

import Triangles from '../../static/img/whatsapp/trianglesLanding.svg'
import landingVideo from '../../static/img/whatsapp/landingVideo.mp4'
import landingVideoES from '../../static/img/whatsapp/es/wl_header_video_es.mp4'
import landingVideoUS from '../../static/img/whatsapp/us/wl_header_video_us.mp4'
import landingVideoUK from '../../static/img/whatsapp/uk/wl_header_video_uk.mp4'
import { OutlineButton } from '../buttons'
import { langs } from '../../langs'

const headerBgColor = '#6F16FF'
const bannerColor = '#4D00CA'
const titleColor = '#FFFFFF'
const subtitleColor = '#E8DAFF'
const hintColor = '#e8daff'
const botonicCTAColor = '#9efb8f'

const BgHeader = styled.div`
  background-color: ${headerBgColor};
  height: 800px;
`

const BotonicText = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-right: 6px;
  margin-left: 6px;
  @media (max-width: 512px) {
    margin-right: 0px;
    margin-left: 0px;
  }
`

const BotonicCTA = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${botonicCTAColor};
  text-decoration: none;
`

const BannerText = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  color: #ffffff;
`

const Title = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 63px;
  color: ${titleColor};
  @media (max-width: 512px) {
    font-size: 36px;
    line-height: 48px;
  }
`

const Subtitle = styled.h2`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  color: ${subtitleColor};
  width: 90%;
  @media (max-width: 512px) {
    font-size: 20px;
    width: 60%;
  }
`

const StyledDoubleCheckIcon = styled(DoubleCheckIcon)`
  margin-right: 10px;
`

const BulletPoint = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: ${subtitleColor};
  margin-bottom: 10px;
`

const ApplyButton = props => (
  <OutlineButton
    {...props}
    color="white"
    borderColor="#FF2B5E"
    hoverBackground="#FF4B76"
    style={{
      background: '#FF2B5E',
      width: '100%',
    }}
  >
    {props.children}
  </OutlineButton>
)

const Hint = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${hintColor};
  margin-top: 15px;
  align-self: center;
`

const LandingNavbar = props => {
  let {
    acf: { header_botonic_cta, header_botonic_text },
  } = useLandingData(props.pageContext.lang)
  return (
    <Flex
      {...props}
      px={[15, 0]}
      py={[20, 10]}
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Flex pt={[0, 3]}>
        <a href={`${langs[props.pageContext.lang]}/`}>
          <DesktopFlex>
            <WhiteLogo />
          </DesktopFlex>
          <MobileFlex mt={1}>
            <WhiteLogoLittle />
          </MobileFlex>
        </a>
      </Flex>
      <Flex>
        <Flex mr={[0, 60]} alignItems="center">
          <Flex
            alignItems={['flex-end', 'baseline']}
            flexDirection={['column', 'row']}
          >
            <BotonicText>{header_botonic_text}</BotonicText>
            <BotonicCTA href={'https://botonic.io/'} target="_blank">
              {header_botonic_cta}
            </BotonicCTA>
          </Flex>
        </Flex>
        <DesktopFlex alignItems="center">
          <BestStartupLogo />
        </DesktopFlex>
      </Flex>
    </Flex>
  )
}

const LandingBanner = props => {
  let {
    acf: { header_banner },
  } = useLandingData(props.pageContext.lang)
  return (
    <Flex
      {...props}
      alignSelf="center"
      css={{ background: bannerColor }}
      width={[1, 1 / 2]}
      className={props.className}
    >
      <Flex
        py={[12, 15]}
        px={[15, 0]}
        width={1}
        justifyContent="center"
        alignItems="center"
      >
        <Flex mr={12}>
          <WhatsappLogo />
        </Flex>
        <BannerText>{header_banner}</BannerText>
      </Flex>
    </Flex>
  )
}

const StyledLandingBanner = styled(LandingBanner)`
  border-radius: 0px;
  @media (min-width: 512px) {
    border-radius: 100px;
  }
`

const ApplyToEA = props => {
  let {
    acf: {
      header_title,
      header_subtitle,
      header_bullet_point_1,
      header_bullet_point_2,
      header_bullet_point_3,
      header_bullet_point_4,
      header_bullet_point_5,
      header_apply_cta,
      header_apply_cta_hint,
    },
  } = useLandingData(props.pageContext.lang)

  let items = [
    header_bullet_point_1,
    header_bullet_point_2,
    header_bullet_point_3,
    header_bullet_point_4,
    header_bullet_point_5,
  ]
  return (
    <>
      <Flex
        {...props}
        px={[15, 0]}
        pt={[36, 47]}
        pb={[52, 58]}
        flexDirection="column"
        width={[1, 1 / 2]}
      >
        <Title>{header_title}</Title>
        <Subtitle>{header_subtitle}</Subtitle>
        <Flex flexWrap="wrap" justifyContent="space-between">
          {items.map((e, i) => {
            return (
              <Flex width={[1, 1 / 2]} key={i}>
                <BulletPoint>
                  <StyledDoubleCheckIcon />
                  {e}
                </BulletPoint>
              </Flex>
            )
          })}
        </Flex>
        <Flex
          pt={[13, 18]}
          width={[1, 1 / 2]}
          justifyContent="center"
          flexDirection="column"
        >
          <ApplyButton href="#whatsappForm">{header_apply_cta}</ApplyButton>
          <Hint>{header_apply_cta_hint}</Hint>
        </Flex>
      </Flex>
    </>
  )
}

const Video = props => {
  let video = undefined
  if (props.lang == 'es_ES') {
    video = landingVideoES
  } else if (props.lang == 'en_GB') {
    video = landingVideoUK
  } else {
    video = landingVideoUS
  }
  return (
    <video style={props.style} autoPlay playsInline loop muted>
      <source src={video} type="video/mp4" />
    </video>
  )
}

const mockupStyle = {
  position: 'absolute',
  top: 160,
  bottom: 0,
  left: '61%',
  height: 598,
  width: 335,
  zIndex: 2,
}
const videoStyle = {
  ...mockupStyle,
  top: mockupStyle.top + 19,
  left: '61%',
  height: 528,
  width: 335,
}

const mockupMobileStyle = {
  position: 'absolute',
  top: 350,
  bottom: 0,
  right: 15,
  height: 250,
  width: 130,
  zIndex: 2,
}

const mobileVideoStyle = {
  ...mockupMobileStyle,
  top: mockupMobileStyle.top - 131,
  right: mockupMobileStyle.right + 14,
  height: 500,
  width: 102,
}

const trianglesStyle = {
  position: 'absolute',
  top: mockupStyle.top,
  bottom: 0,
  left: '47%',
  maxHeight: 622,
  maxWidth: 872,
  width: 'auto',
  height: 'auto',
  zIndex: 1,
}

const Figures = props => {
  return (
    <>
      <DesktopFlex>
        <Video lang={props.lang} style={videoStyle} />
        <Img style={mockupStyle} fluid={props.mockup.childImageSharp.fluid} />
      </DesktopFlex>
      <MobileFlex>
        <Video lang={props.lang} style={mobileVideoStyle} />
        <Img
          style={mockupMobileStyle}
          fluid={props.mockup.childImageSharp.fluid}
        />
      </MobileFlex>
      <DesktopFlex>
        <Triangles style={trianglesStyle} />
      </DesktopFlex>
    </>
  )
}

const Header = props => {
  const { mockup } = useStaticQuery(
    graphql`
      query {
        mockup: file(relativePath: { eq: "whatsapp/iphoneMockup.png" }) {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  return (
    <>
      <Section
        background={
          <>
            <D>
              <BgHeader style={{ height: 1100 }} />
            </D>
            <M>
              <BgHeader style={{ height: 1100 }} />
            </M>
          </>
        }
      >
        <Flex flexDirection="column">
          <LandingNavbar pageContext={props.pageContext} />
          <StyledLandingBanner pageContext={props.pageContext} />
          <ApplyToEA css={{ zIndex: 3 }} pageContext={props.pageContext} />
        </Flex>
        <Figures lang={props.pageContext.lang} mockup={mockup} />
      </Section>
    </>
  )
}

export default Header
