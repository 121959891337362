import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { Flex, Box } from '@rebass/grid'
import Img from 'gatsby-image'

import Section from '../Section'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import { useLandingData } from '../../hooks'

const bgColor = '#f2f2f2'
const contentColor = '#441d76'
const titleColor = '#441d76'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: ${bgColor};
`

const Content = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: ${contentColor};
`

const Title = styled.h3`
  font-size: 30px;
  line-height: 40px;
  text-align: initial;
  color: ${titleColor};
  max-width: 350px;
`

const StyledImg = styled(Img)`
  max-width: 855px;
  width: 90vw;
  @media (min-width: 512px) {
    left: 7vw;
  }
`

const CountriesBg = props => (
  <>
    <DesktopFlex>
      <Box>
        <StyledImg fluid={props.desktop.childImageSharp.fluid} />
      </Box>
    </DesktopFlex>
    <MobileFlex>
      <Box>
        <StyledImg fluid={props.mobile.childImageSharp.fluid} />
      </Box>
    </MobileFlex>
  </>
)

const Conversations = props => (
  <>
    <DesktopFlex>
      <Box>
        <StyledImg fluid={props.desktop.childImageSharp.fluid} />
      </Box>
    </DesktopFlex>
    <MobileFlex>
      <Box>
        <StyledImg fluid={props.mobile.childImageSharp.fluid} />
      </Box>
    </MobileFlex>
  </>
)

const ConversationsContainer = props => (
  <Flex flexDirection={['column', 'row']} mt={[5, 6]}>
    {props.children}
  </Flex>
)

// const StyledImgConversations = styled(Img)`
//   width: 100%;

//   @media (min-width: 512px) {
//     position: relative;
//     top: -60px;
//     left: 60px;
//   }
// `

const Enterprise = props => {
  let {
    acf: { enterprise_title, enterprise_tagline, enterprise_text },
  } = useLandingData(props.pageContext.lang)

  const {
    backgroundImage,
    messagesBgImage,
    backgroundImageMobile,
    backgroundImageES,
    messagesBgImageES,
    backgroundImageMobileES,
    messagesBgImageMobile,
    messagesBgImageMobileES,
  } = useStaticQuery(
    graphql`
      query {
        backgroundImage: file(
          relativePath: { eq: "whatsapp/enterprise_map@3x.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        backgroundImageMobile: file(
          relativePath: { eq: "whatsapp/enterprise_map_mobile@3x.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 380) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        messagesBgImage: file(
          relativePath: { eq: "whatsapp/enterprise_msg@3x.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        messagesBgImageMobile: file(
          relativePath: { eq: "whatsapp/enterprise_msg_mobile@3x.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        backgroundImageES: file(
          relativePath: { eq: "whatsapp/enterprise_map_es@3x.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        backgroundImageMobileES: file(
          relativePath: { eq: "whatsapp/enterprise_map_mobile_es@3x.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 380) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        messagesBgImageES: file(
          relativePath: { eq: "whatsapp/enterprise_msg_es@3x.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        messagesBgImageMobileES: file(
          relativePath: { eq: "whatsapp/enterprise_msg_mobile_es@3x.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  let { lang } = props.pageContext
  return (
    <Section pt={[33, 33]} pb={[33, 66]} background={<Bg />}>
      <Flex mx={[15, 0]} justifyContent="center" py={4} flexDirection="column">
        <Title style={{ alignSelf: 'center', textAlign: 'center' }}>
          {enterprise_title}
        </Title>
        <CountriesBg
          desktop={lang == 'es_ES' ? backgroundImageES : backgroundImage}
          mobile={
            lang == 'es_ES' ? backgroundImageMobileES : backgroundImageMobile
          }
        />
        <ConversationsContainer>
          <Flex flexDirection="column" width={[1, 1 / 3]}>
            <Title>{enterprise_tagline}</Title>
            <Content dangerouslySetInnerHTML={{ __html: enterprise_text }} />
          </Flex>
          <Flex flexDirection="column" width={[1, 2 / 3]}>
            <Conversations
              desktop={lang == 'es_ES' ? messagesBgImageES : messagesBgImage}
              mobile={
                lang == 'es_ES'
                  ? messagesBgImageMobileES
                  : messagesBgImageMobile
              }
            />
          </Flex>
        </ConversationsContainer>
      </Flex>
    </Section>
  )
}

export default Enterprise
