import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { OutlineButton } from '../buttons'
import { useLandingData } from '../../hooks'

import Section from '../Section'
import Triangles from '../../static/img/whatsapp/triangles_early_svg.svg'
import TrianglesMobile from '../../static/img/whatsapp/triangles_early_svg_mobile.svg'
import { DesktopFlex, MobileFlex } from '../breakpoints'

const bgColor = '#ff2b5e'
const titleColor = '#ffffff'
const contentColor = '#ffffff'
const hintColor = '#ffcdd9'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: ${bgColor};
`

const Title = styled.h3`
  font-size: 34px;
  line-height: 45px;
  color: ${titleColor};
  margin-bottom: 11px;
`

const Content = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: ${contentColor};
  margin-bottom: 22px;
`

const Hint = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  align-self: center;
  color: ${hintColor};
`

const StyledTriangles = styled(Triangles)`
  position: absolute;
  right: 0px;
  bottom: -30px;
`

const StyledTrianglesMobile = styled(TrianglesMobile)`
  position: absolute;
  left: 0px;
  bottom: -40px;
  width: 380px;
`

const EarlyAccess = props => {
  let {
    acf: {
      early_access_title,
      early_access_text,
      early_access_button,
      early_access_button_hint,
    },
  } = useLandingData(props.pageContext.lang)

  const bg = (
    <Bg>
      <DesktopFlex>
        <StyledTriangles />
      </DesktopFlex>
      <MobileFlex>
        <StyledTrianglesMobile />
      </MobileFlex>
    </Bg>
  )

  return (
    <Section px={[15, 0]} background={bg}>
      <Flex pt={[50, 50]} pb={[70, 50]} flexDirection="column" width={[1, 0.4]}>
        <Title>{early_access_title}</Title>
        <Content>{early_access_text}</Content>
        <Flex flexDirection="column" width={[1, 2 / 3]}>
          <OutlineButton
            color="white"
            hoverBackground="#ff4d76"
            borderColor="white"
            href="#whatsappForm"
            style={{ marginBottom: 10 }}
          >
            {early_access_button}
          </OutlineButton>
          <Hint style={{ paddingTop: '6px' }}>{early_access_button_hint}</Hint>
        </Flex>
      </Flex>
    </Section>
  )
}

export default EarlyAccess
