import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'

import Section from '../Section'
import Triangle1 from '../../static/img/whatsapp/bg_triangle_1.svg'
import Triangle2 from '../../static/img/whatsapp/bg_triangle_2.svg'
import { D, DesktopFlex, MobileFlex } from '../breakpoints'
import { useLandingData } from '../../hooks'

const bgColor = '#6f16ff'
const quoteMarksColor = '#ffffff'
const businessContentColor = '#ffffff'
const customerTextColor = '#ffffff'
const titleColor = '#ffffff'

const Bg = styled.div`
  position: relative;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: ${bgColor};
  transform-origin: top left;
`

const QuoteMarks = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 95px;
  color: ${quoteMarksColor};
`

const BusinessContent = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: ${businessContentColor};
`

const CustomerName = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: ${customerTextColor};
`

const CustomerPosition = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${customerTextColor};
`

const Title = styled.h3`
  font-size: 34px;
  line-height: 45px;
  text-align: center;
  color: ${titleColor};
  padding-bottom: 122px;
  @media (max-width: 512px) {
    padding-bottom: 50px;
  }
`

const trianglesStyle = { position: 'absolute', top: -80, left: -80, zIndex: -1 }

const trianglesMobileStyle = {
  position: 'absolute',
  top: 0,
  left: -100,
  zIndex: -1,
}

const Triangle = props => {
  if (props.triangle == 1) {
    return (
      <>
        <DesktopFlex>
          <Triangle1 style={trianglesStyle} />
        </DesktopFlex>
        <MobileFlex>
          <Triangle1 style={trianglesMobileStyle} />
        </MobileFlex>
      </>
    )
  } else if (props.triangle == 2) {
    return (
      <>
        <DesktopFlex>
          <Triangle2 style={trianglesStyle} />
        </DesktopFlex>
        <MobileFlex>
          <Triangle2 style={trianglesMobileStyle} />
        </MobileFlex>
      </>
    )
  } else {
    return null
  }
}

const BusinessQuote = props => {
  return (
    <Flex {...props} flexDirection="column">
      <Flex css={{ position: 'relative' }}>
        <QuoteMarks style={{ position: 'absolute', top: -30, left: -30 }}>
          “
        </QuoteMarks>
        <Triangle triangle={props.triangle} />
        <Flex flexDirection="column">
          <BusinessContent
            dangerouslySetInnerHTML={{ __html: props.quote.text }}
          />
          <Flex css={{ position: 'relative' }}>
            <QuoteMarks style={props.lastQuoteStyle}>”</QuoteMarks>
          </Flex>
        </Flex>
      </Flex>
      {/* TODO: change icon for VW image */}
      {/* <Img fixed={volkswagen.childImageSharp.fixed} /> */}
      <CustomerName>{props.quote.customer_name}</CustomerName>
      <CustomerPosition>{props.quote.customer_position}</CustomerPosition>
    </Flex>
  )
}

const QuoteContainer = props => (
  <Flex {...props} flexDirection={['column', 'row']}>
    {props.children}
  </Flex>
)

const Business = props => {
  let {
    acf: { business_title, business_quote_1, business_quote_2 },
  } = useLandingData(props.pageContext.lang)
  const { backgroundImage, firstLogos, secondLogos } = useStaticQuery(
    graphql`
      query {
        backgroundImage: file(
          relativePath: { eq: "whatsapp/triangles@3x.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        firstLogos: file(relativePath: { eq: "whatsapp/logos_first.png" }) {
          childImageSharp {
            fluid(maxWidth: 411) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        secondLogos: file(relativePath: { eq: "whatsapp/logos_second.png" }) {
          childImageSharp {
            fluid(maxWidth: 411) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  let logosMarginTop = -30
  let quoteLogosDist = 126

  const bg = (
    <Bg>
      <D>
        <Img fluid={backgroundImage.childImageSharp.fluid} />
      </D>
    </Bg>
  )

  const Logo = props => (
    <Flex mt={logosMarginTop} width={[1, 1]} py={6}>
      <Bg
        style={{
          background: 'transparent',
        }}
      >
        <Img fluid={props.logo.childImageSharp.fluid} />
      </Bg>
    </Flex>
  )

  return (
    <Section px={[45, 0]} pt={[50, 59]} pb={[80, 114]} background={bg}>
      <Flex flexDirection="column">
        <Title>{business_title}</Title>
        <QuoteContainer>
          <BusinessQuote
            pr={[0, quoteLogosDist]}
            width={1}
            quote={business_quote_1}
            triangle={1}
            lastQuoteStyle={{ position: 'absolute', top: -45, left: '50%' }}
          />
          <Logo logo={firstLogos} />
        </QuoteContainer>
        <QuoteContainer mt={[-180, 0]}>
          <Logo logo={secondLogos} />
          <BusinessQuote
            pl={[0, quoteLogosDist]}
            width={1}
            quote={business_quote_2}
            triangle={2}
            lastQuoteStyle={{ position: 'absolute', top: -50, left: '90%' }}
          />
        </QuoteContainer>
      </Flex>
    </Section>
  )
}

export default Business
