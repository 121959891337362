import React from 'react'
import { BreakpointProvider } from 'react-socks'
import { Flex } from '@rebass/grid'

import AutomationChatbots from '../components/whatsapp/automationChatbots'
import Business from '../components/whatsapp/business.js'
import Enterprise from '../components/whatsapp/enterprise'
import EarlyAccess from '../components/whatsapp/early_access'
import Head from '../components/head'
import Header from '../components/whatsapp/header'
import LandingFooter from '../components/whatsapp/landing_footer'
import WhatsappForm from '../components/whatsapp/form'
import UsesCase from '../components/whatsapp/use_case'

import { useLandingData } from '../hooks/use-landing-data'

import '../styles/main.scss'

const WhatsappLanding = props => {
  const {
    yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
  } = useLandingData(props.pageContext.lang)

  return (
    <BreakpointProvider>
      <Head
        alternates={props.pageContext.i18n}
        autopilot={props.pageContext.autopilot}
        description={yoast_wpseo_metadesc}
        lang={props.pageContext.lang}
        title={yoast_wpseo_title}
        uri={props.pageContext.relativeUrl}
      />
      <Flex flexDirection="column" css={{ overflow: 'hidden' }}>
        <Header {...props} />
        <AutomationChatbots {...props} />
        <Business {...props} />
        <Enterprise {...props} />
        <EarlyAccess {...props} />
        {/* <WhyHubtype {...props} /> */}
        <UsesCase {...props} />
        <WhatsappForm {...props} />
        <LandingFooter {...props} />
      </Flex>
    </BreakpointProvider>
  )
}
export default WhatsappLanding
