
const CUSTOM_FIELD_VALUES = {
  traffic_volume_1: 1,
  traffic_volume_2: 2000,
  traffic_volume_3: 5000,
  traffic_volume_4: 10000,
  traffic_volume_5: 100000,
  traffic_volume_6: 500000,
  traffic_volume_7: 2000000,
  est_mau_1: 1,
  est_mau_2: 1000,
  est_mau_3: 5000,
  est_mau_4: 10000,
  est_mau_5: 20000,
  support_people_1: '1',
  support_people_2: '3',
  support_people_3: '11',
  support_people_4: '26',
  support_people_5: '50',
  use_case_1: 'Customer Service',
  use_case_2: 'Marketing',
  use_case_3: 'Sales',
  use_case_4: 'Other',
}

export const getFieldValue = field => CUSTOM_FIELD_VALUES[field] || ''
