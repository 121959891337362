import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import WhiteLogo from '../../static/img/hubtype_logo_white.svg'
import { getCurrentYear } from '../../utils/date'

const FooterItem = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  color: #b282ff;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
  text-decoration: none;
`
const FooterContainer = styled(Flex)`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -150px;
    height: 100%;
    width: 100%;
    left: 0px;
    right: 0px;
    background-color: #6500cb;
    z-index: -10;
  }
`

const LandingFooter = props => {
  return (
    <FooterContainer
      pt={[30, 90]}
      pb={20}
      css={{
        position: 'relative',
        background: '#6500cb',
      }}
      flexDirection="column"
      alignItems="center"
    >
      <Flex>
        <Flex mr={[20, 572]}>
          <a href="/">
            <WhiteLogo />
          </a>
        </Flex>
        <Flex flexDirection={['column', 'row']}>
          <Flex flexWrap="wrap">
            <FooterItem href="/privacy">Privacy policy</FooterItem>
            <FooterItem href="/cookies">Cookies</FooterItem>
            <FooterItem href="/terms">Terms of service</FooterItem>
          </Flex>
          <Flex>
            <FooterItem style={{ color: '#b282ff' }}>
              © { getCurrentYear() } All rights reserved
            </FooterItem>
          </Flex>
        </Flex>
      </Flex>
    </FooterContainer>
  )
}

export default LandingFooter
