import { getFieldValue } from "../../constants/forms"

export const DEFAULT_VALUE_TRAFFIC = 'No'
const SELECTABLE_FIELD_TRAFFIC = 'trafficVolume'
const SELECTABLE_FIELD_MAU = 'estMau'

const emptyForm = {
  accessAPI: '',
  company: '',
  comesFrom: 'web',
  country: '',
  developer: '',
  email: '',
  estMAU: '',
  GDPRcheckBox: false,
  firstName: '',
  lastName: '',
  message: '',
  other: 'false',
  phone: '',
  supportPeople: '',
  title: '',
  traffic: DEFAULT_VALUE_TRAFFIC,
  trafficVolume: '',
  use: '',
  
  facebook: 'false',
  telegram: 'false',
  webchat: 'false',
  whatsapp: 'false',
}

export const getEmptyForm = ({
  mauOptions,
  supportOptions,
  trafficVolumeOptions, 
  useOptions,
}) => ({
  ...emptyForm,
  ...{
    estMAUOptions: [
      { est_mau_1: mauOptions.option_1 },
      { est_mau_2: mauOptions.option_2 },
      { est_mau_3: mauOptions.option_3 },
      { est_mau_4: mauOptions.option_4 },
      { est_mau_5: mauOptions.option_5 },
    ],
    supportPeopleOptions: [
      { support_people_1: supportOptions.option_1 },
      { support_people_2: supportOptions.option_2 },
      { support_people_3: supportOptions.option_3 },
      { support_people_4: supportOptions.option_4 },
      { support_people_5: supportOptions.option_5 },
    ],
    trafficVolumeOptions: [
      { traffic_volume_1: trafficVolumeOptions.option_1 },
      { traffic_volume_2: trafficVolumeOptions.option_2 },
      { traffic_volume_3: trafficVolumeOptions.option_3 },
      { traffic_volume_4: trafficVolumeOptions.option_4 },
      { traffic_volume_5: trafficVolumeOptions.option_5 },
      { traffic_volume_6: trafficVolumeOptions.option_6 },
      { traffic_volume_7: trafficVolumeOptions.option_7 },
    ],
    useOptions: [
      { use_case_1: useOptions.option_1 },
      { use_case_2: useOptions.option_2 },
      { use_case_3: useOptions.option_3 },
      { use_case_4: useOptions.option_4 },
    ],
  }
})

export const hydrateSegmentData = userData => {
  let estMau = ''
  let trafficVolume = getFieldValue(userData.trafficVolume)

  if (userData.traffic === DEFAULT_VALUE_TRAFFIC) {
    estMau = getFieldValue(userData.estMAU)
    trafficVolume = ''
  }

  return {
    estMau,
    supportPeople: getFieldValue(userData.supportPeople),
    trafficVolume,
    use: getFieldValue(userData.use),
  }
}

export const isValidForm = fields => {
  let invalidValues = Object.entries(fields)
    .filter(([key, value]) => key !== SELECTABLE_FIELD_MAU && key !== SELECTABLE_FIELD_TRAFFIC)
    .some(([key, value]) => value === '')

  return !invalidValues && (fields[SELECTABLE_FIELD_MAU] !== '' || fields[SELECTABLE_FIELD_TRAFFIC] !== '')
}
