import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { Flex } from '@rebass/grid'
import Img from 'gatsby-image'

import Section from '../Section'
import { D } from '../breakpoints'
import Triangles1 from '../../static/img/whatsapp/triangles_use_cases_1.svg'
import Triangles2 from '../../static/img/whatsapp/triangles_use_cases_2.svg'
import { useLandingData } from '../../hooks'

const useCaseBgColor = '#6f16ff'
const centricUseCaseBgColor = '#6619e3'

const latColumns = 0.37

const BgDesktop = styled.div`
  height: 150vh;
  width: 100vw;
  background-image: linear-gradient(
    90deg,
    ${useCaseBgColor} ${latColumns * 100}%,
    ${centricUseCaseBgColor} ${latColumns * 100}%,
    ${centricUseCaseBgColor} ${100 - latColumns * 100}%,
    ${useCaseBgColor} ${100 - latColumns * 100}%
  );
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0px;
`

const Content = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  padding-bottom: 40px;
  color: #e8daff;
`

const Title = styled.h2`
  font-size: 34px;
  line-height: 45px;
  align-self: center;
  color: #ffffff;
  transform: skewY(0deg);
  margin-top: 20px;
  @media (max-width: 512px) {
    transform: skewY(2deg);
  }
`
const CaseTitle = styled.h3`
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 5px;
  padding-bottom: 10px;
  color: #ffffff;
`

const TitleContainer = styled(Flex)`
  background: none;
  @media (max-width: 512px) {
    background: ${useCaseBgColor};
    transform: skewY(-2deg);
  }
`

const StyledTriangles1 = styled(Triangles1)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 5%;
  @media (max-width: 512px) {
    top: 20;
    left: -10%;
  }
`

const StyledTriangles2 = styled(Triangles2)`
  position: absolute;
  z-index: 1;
  bottom: 15%;
  right: -24%;
  @media (min-width: 512px) {
    top: 0;
    right: 7%;
  }
`

const Case = props => (
  <Flex
    flexDirection="column"
    css={{
      background: props.color,
      transform: `skewY(${props.flexSkewY}deg)`,
    }}
  >
    <Flex pt={40} px={[15, 0]} flexDirection="column">
      <Flex justifyContent="center">
        <Img
          style={{
            width: '300px',
            transform: `skewY(${props.skewY}deg)`,
          }}
          fluid={props.img.childImageSharp.fluid}
        />
      </Flex>
      <CaseTitle style={{ transform: `skewY(${props.skewY}deg)` }}>
        {props.case.title}
      </CaseTitle>
      <Content style={{ transform: `skewY(${props.skewY}deg)` }}>
        {props.case.text}
      </Content>
    </Flex>
  </Flex>
)

const UseCase = props => {
  let {
    acf: { use_cases_title, use_cases_1, use_cases_2, use_cases_3 },
  } = useLandingData(props.pageContext.lang)
  const {
    customerServiceES,
    salesES,
    marketingES,
    customerServiceUS,
    salesUS,
    marketingUS,
    customerServiceUK,
    salesUK,
    marketingUK,
  } = useStaticQuery(
    graphql`
      query {
        customerServiceES: file(
          relativePath: { eq: "whatsapp/es/Customer_service_cut_es.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 275, quality: 85) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        salesES: file(relativePath: { eq: "whatsapp/es/sales_cut_es.png" }) {
          childImageSharp {
            fluid(maxWidth: 275, quality: 85) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        marketingES: file(
          relativePath: { eq: "whatsapp/es/marketing_cut_es.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 275, quality: 85) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        customerServiceUS: file(
          relativePath: { eq: "whatsapp/us/Customer_service_cut_us.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 275, quality: 85) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        salesUS: file(relativePath: { eq: "whatsapp/us/sales_cut_us.png" }) {
          childImageSharp {
            fluid(maxWidth: 275, quality: 85) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        marketingUS: file(
          relativePath: { eq: "whatsapp/us/marketing_cut_us.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 275, quality: 85) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        customerServiceUK: file(
          relativePath: { eq: "whatsapp/uk/Customer_service_cut_uk.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 275, quality: 85) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        salesUK: file(relativePath: { eq: "whatsapp/uk/sales_cut_uk.png" }) {
          childImageSharp {
            fluid(maxWidth: 275, quality: 85) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        marketingUK: file(
          relativePath: { eq: "whatsapp/uk/marketing_cut_uk.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 275, quality: 85) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  let customerService = undefined
  let sales = undefined
  let marketing = undefined

  if (props.pageContext.lang == 'es_ES') {
    customerService = customerServiceES
    sales = salesES
    marketing = marketingES
  } else if (props.pageContext.lang == 'en_GB') {
    customerService = customerServiceUK
    sales = salesUK
    marketing = marketingUK
  } else {
    customerService = customerServiceUS
    sales = salesUS
    marketing = marketingUS
  }

  return (
    <Section
      mt={-1}
      background={
        <D>
          <BgDesktop />
        </D>
      }
    >
      <Flex
        pt={[0, 40]}
        pb={[0, 70]}
        justifyContent="center"
        flexDirection="column"
      >
        <TitleContainer
          width={1}
          css={{ alignSelf: 'center', zIndex: 3 }}
          mt={-1}
          justifyContent="center"
          flexDirection="column"
        >
          <Title>{use_cases_title}</Title>
        </TitleContainer>
        <Flex flexDirection={['column', 'row']}>
          <StyledTriangles1 />
          <Flex mr={[0, 75]} width={[1, latColumns]} mt={-2}>
            <Case
              case={use_cases_1}
              img={customerService}
              color={useCaseBgColor}
              flexSkewY={0}
              skewY={0}
            />
          </Flex>
          <Flex width={[1, 1 - 2 * latColumns]} mt={-2}>
            <Case
              case={use_cases_2}
              img={sales}
              color={centricUseCaseBgColor}
              flexSkewY={0}
              skewY={0}
            />
          </Flex>
          <StyledTriangles2 />
          <Flex ml={[0, 75]} width={[1, latColumns]} mt={-2}>
            <Case
              case={use_cases_3}
              img={marketing}
              color={useCaseBgColor}
              flexSkewY={-2}
              skewY={2}
            />
          </Flex>
        </Flex>
      </Flex>
    </Section>
  )
}

export default UseCase
