
const LOCALE_US = 'en_US'
const LOCALE_UK = 'en_GB'
const LOCALE_ES = 'es_ES'
const DEFAULT_LOCALE = LOCALE_US

const SUPPORTED_LANGS = {
  [LOCALE_US]: '',
  [LOCALE_UK]: '/uk',
  [LOCALE_ES]: '/es',
}

const getLangPath = locale => SUPPORTED_LANGS[locale] || ''
const isLangDeprecated = locale => locale === LOCALE_UK

function Translation(locale, path, uri) {
  return {
    deprecated: isLangDeprecated(locale),
    locale,
    path,
    uri,
  }
}

const SUPPORTED_LANGS_SEO = 
  Object.entries(SUPPORTED_LANGS).reduce((acc, [locale, path]) => {
    acc[locale] = {
      deprecated: isLangDeprecated(locale),
      locale,
      path,
    }
    return acc
  }, {})

module.exports = {
  DEFAULT_LOCALE,
  LOCALE_ES,
  LOCALE_UK,
  LOCALE_US,
  getLangPath,
  isLangDeprecated,
  langs: SUPPORTED_LANGS,
  seoLangs: SUPPORTED_LANGS_SEO,
  Translation,
}
