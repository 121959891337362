import React, { useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import styled from 'styled-components'
import * as EmailValidator from 'email-validator'
import { Flex, Box } from '@rebass/grid'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Triangle from '../../static/img/whatsapp/triangle_whatsappForm.svg'
import Section from '../Section'
import { DEFAULT_VALUE_TRAFFIC, getEmptyForm, hydrateSegmentData, isValidForm } from '../../data/forms/whatsapp'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import {
  FormContainer,
  Selector,
  FieldContainer,
  RadioInput,
  MessageBox,
  Text,
} from '../forms'
import { OutlineButton } from '../buttons'
import { useLandingData } from '../../hooks'

const Title = styled.h2`
  font-size: 34px;
  line-height: 45px;
  color: #441d76;
`

const Subtitle = styled.h3`
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
`

const FeedbackTitle = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 132.19%;
  color: #333333;
  padding-bottom: 5px;
`

const FeedbackContent = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  padding-bottom: 15px;
  color: #696973;
`

const Bg = styled.div`
  position: relative;
  overflow: hidden;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #f2f2f2;
  transform-origin: top left;
`

const BoldTagline = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 10px;
`
const Separator = styled(Flex)`
  width: 100%;
  height: 0px;
  border: 1px solid #dadada;
`

const theme = createMuiTheme({
  palette: {
    primary: { main: '#6F16FF' },
    secondary: { main: '#6F16FF' },
  },
  typography: { useNextVariants: true },
})

const TaglineContainer = props => <Flex {...props}>{props.children}</Flex>

const WhatsappForm = props => {
  let {
    acf: {
      form_title,
      form_subtitle,
      first_name,
      last_name,
      email,
      phone,
      job_title,
      company,
      country,
      radio_1,
      radio_2,
      radio_3,
      intended_use_selector,
      traffic_volume_selector,
      est_mau_selector,
      support_people_selector,
      message_tagline,
      message_placeholder,
      i_agree,
      privacy_policy,
      sent_feedback,
      message_feedback_text,
      message_feedback_text_uncomplete,
      send_button,
      choose_option,
      mail_error,
      channels_interested,
    },
  } = useLandingData(props.pageContext.lang)
  
  let initialState = getEmptyForm({
    mauOptions: est_mau_selector,
    supportOptions: support_people_selector,
    trafficVolumeOptions: traffic_volume_selector,
    useOptions: intended_use_selector,
  })

  const [isSend, setIsSend] = useState(undefined)
  const [emailCorrect, setEmailCorrect] = useState(true)
  const [userData, updateData] = useState(initialState)

  const handleChange = name => event => {
    updateData({ ...userData, [name]: event.target.value })
  }
  const validateData = userData => {
    //Marketing need to autopilot
    userData.accessAPI =
      userData.accessAPI == 'End client' ? 'Client' : 'Partner'

    let messageChannels = {
      whatsapp: userData.whatsapp,
      facebook: userData.facebook,
      telegram: userData.telegram,
      webchat: userData.webchat,
      other: userData.other,
    }
    let propertiesRequired = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      phone: userData.phone,
      title: userData.title,
      messageChannels,
      developer: userData.developer,
      accessAPI: userData.accessAPI,
      traffic: userData.traffic,
      company: userData.company,
      country: userData.country,
      GDPRcheckBox: userData.GDPRcheckBox,
      lang: props.pageContext.lang,
      comesFrom: userData.comesFrom,
      ...hydrateSegmentData(userData)
    }

    let propertiesNotRequired = {
      message: userData.message,
    }
    let properties = { ...propertiesRequired, ...propertiesNotRequired }

    if (isValidForm(propertiesRequired)) {
      if (
        userData.email.includes('mail.') ||
        !EmailValidator.validate(userData.email)
      ) {
        setIsSend(false)
        setEmailCorrect(false)
      } else {
        setIsSend(true)
        sendClick(properties)
      }
    } else {
      setIsSend(false)
    }
  }
  const sendClick = properties => {
    if (typeof window !== 'undefined') {
      setEmailCorrect(true)
      window.analytics.identify(properties)
      window.analytics.track('Whatsapp Form', properties)
      let {
        email: Email,
        firstName: FirstName,
        lastName: LastName,
        ...custom
      } = properties
      window.Autopilot.run('associate', {
        Email,
        FirstName,
        LastName,
        custom,
      })
      updateData(initialState)
    }
  }
  const Feedback = props => {
    if (isSend == true) {
      return (
        <>
          <FeedbackTitle>{sent_feedback}</FeedbackTitle>
          <FeedbackContent>{message_feedback_text}</FeedbackContent>
        </>
      )
    } else if (isSend == false) {
      return (
        <FeedbackContent>{message_feedback_text_uncomplete}</FeedbackContent>
      )
    } else {
      return <></>
    }
  }

  return (
    <Section id="whatsappForm" my={[80, 60]} px={[15, 0]} background={<Bg />}>
      <TaglineContainer width={[1, 1]} flexDirection={'column'} mb={[20, 70]}>
        <Flex
          justifyContent={['center', 'left']}
          alignItems="center"
          flexDirection={['column', 'row']}
        >
          <Box width={[1, 450]} flexDirection={['column']} pb={[40, 0]}>
            <Title>{form_title}</Title>
            <Subtitle>{form_subtitle}</Subtitle>
          </Box>
        </Flex>
        <DesktopFlex>
          <Triangle
            style={{ position: 'absolute', right: 184, top: -42, zIndex: -1 }}
          />
        </DesktopFlex>
        <MobileFlex>
          <Triangle
            style={{ position: 'absolute', top: 242, right: -50, zIndex: -1 }}
          />
        </MobileFlex>
      </TaglineContainer>

      <FormContainer>
        <Text
          onChange={handleChange('firstName')}
          label={`${first_name}`}
          value={userData.firstName}
        />
        <Text
          onChange={handleChange('lastName')}
          label={`${last_name}`}
          value={userData.lastName}
        />
        <Text
          error={!emailCorrect}
          onChange={handleChange('email')}
          label={`${email}`}
          value={userData.email}
          type="email"
          name="email"
          autoComplete="email"
          helperText={emailCorrect == false ? mail_error : ''}
        />
        <Text
          onChange={handleChange('phone')}
          label={`${phone}`}
          value={userData.phone}
        />
        <Text
          onChange={handleChange('title')}
          label={`${job_title}`}
          value={userData.title}
        />
        <FieldContainer>
          <Flex my={[0, 20]} flexDirection="column">
            <RadioInput
              style={{ marginLeft: -15 }}
              radio={radio_1}
              value={userData.developer}
              onChange={handleChange('developer')}
              aslabel={'true'}
            />
          </Flex>
        </FieldContainer>
        <Text
          onChange={handleChange('company')}
          label={`${company}`}
          value={userData.company}
        />
        <Text
          onChange={handleChange('country')}
          label={`${country}`}
          value={userData.country}
        />
        <FieldContainer>
          <Flex my={[10, 20]} flexDirection="column" width={[1, '110%']}>
            <RadioInput
              style={{ marginLeft: -15 }}
              radio={radio_3}
              value={userData.accessAPI}
              onChange={handleChange('accessAPI')}
              aslabel={'true'}
            />
          </Flex>
        </FieldContainer>
        <Separator my={50} />
        <Flex flexDirection={['column', 'row']}>
          <FieldContainer style={{ marginBottom: 10 }}>
            <Selector
              item={intended_use_selector}
              label={`${choose_option}`}
              value={userData.use}
              onChange={handleChange('use')}
              options={userData.useOptions}
              identifier={'use_case'}
            />
          </FieldContainer>
          <FieldContainer>
            <Flex mb={[10, 30]} flexDirection="column">
              <RadioInput
                style={{ marginLeft: -15 }}
                radio={radio_2}
                value={userData.traffic}
                onChange={handleChange('traffic')}
              />
              {userData.traffic !== DEFAULT_VALUE_TRAFFIC && (
                <Selector
                  item={traffic_volume_selector}
                  label={`${choose_option}`}
                  value={userData.trafficVolume}
                  onChange={handleChange('trafficVolume')}
                  options={userData.trafficVolumeOptions}
                  identifier={'traffic_volume'}
                />
              )}
              {userData.traffic === DEFAULT_VALUE_TRAFFIC && (
                <Selector
                  item={est_mau_selector}
                  label={`${choose_option}`}
                  value={userData.estMAU}
                  onChange={handleChange('estMAU')}
                  options={userData.estMAUOptions}
                  identifier={'est_mau'}
                />
              )}
            </Flex>
          </FieldContainer>
          <FieldContainer style={{ marginBottom: 10 }}>
            <Selector
              item={support_people_selector}
              label={`${choose_option}`}
              value={userData.supportPeople}
              onChange={handleChange('supportPeople')}
              options={userData.supportPeopleOptions}
              identifier={'support_people'}
            />
          </FieldContainer>
        </Flex>
        <Separator my={50} />
        <Flex flexDirection={'column'}>
          <Subtitle>{channels_interested.title}</Subtitle>
          <Flex flexDirection={['column', 'row']}>
            {Object.keys(channels_interested.channels).map((option, i) => {
              return (
                <Flex key={i} flexDirection="row" pr={[0, 3]}>
                  <MuiThemeProvider theme={theme}>
                    <Checkbox
                      checked={userData[option] == 'true' ? true : false}
                      onChange={handleChange(option)}
                      style={{ width: 45 }}
                      value={userData[option] == 'true' ? false : true}
                    />
                    <a style={{ paddingTop: 8 }}>
                      {channels_interested.channels[option]}
                    </a>
                  </MuiThemeProvider>
                </Flex>
              )
            })}
          </Flex>
        </Flex>
        <Separator my={50} />
        <Flex width={1} flexDirection="column">
          <BoldTagline>{message_tagline}</BoldTagline>
          <FieldContainer>
            <MessageBox
              label={`${message_placeholder}`}
              onChange={handleChange('message')}
              value={userData.message}
            />
          </FieldContainer>
          <Flex mb={[20, 30]} alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={userData.GDPRcheckBox}
                  onChange={props =>
                    updateData({
                      ...userData,
                      GDPRcheckBox: props.target.checked,
                    })
                  }
                />
              }
              label={
                <label>
                  {i_agree}
                  <span> </span>
                  <a href="/privacy">{privacy_policy}</a>
                </label>
              }
            />
          </Flex>
          <Flex height={40} width={[1, '17.5%']}>
            <OutlineButton
              color="white"
              hoverBackground="#FF4B76"
              style={{
                background: '#FF2B5E',
                height: '40px',
                width: '100%',
              }}
              onClick={() => validateData(userData)}
            >
              {send_button}
            </OutlineButton>
          </Flex>
          <Flex mt={[15, 10]} mx={[15, 0]} flexDirection="column">
            <Feedback />
          </Flex>
        </Flex>
      </FormContainer>
    </Section>
  )
}

export default WhatsappForm
