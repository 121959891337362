import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import Section from '../Section'
import { Flex } from '@rebass/grid'
import { useLandingData } from '../../hooks'

const bgColor = '#f2f2f2'
const titleColor = '#441d76'
const bulletPointsColor = '#441d76'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: ${bgColor};
`
const Title = styled.h2`
  line-height: 40px;
  color: ${titleColor};
`

const BulletPointsText = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin-top: 0px;
  margin-bottom: -10px;
  color: ${bulletPointsColor};
`
const AutomationChatbots = props => {
  let {
    acf: {
      automation_title,
      automation_bullet_point_1,
      automation_bullet_point_2,
      automation_bullet_point_3,
      automation_bullet_point_4,
      automation_bullet_point_5,
      automation_bullet_point_6,
    },
  } = useLandingData(props.pageContext.lang)

  const {
    attendCustomersIcon,
    automateUpIcon,
    buildChatbotIcon,
    easilyDeployIcon,
    integrateAIIcon,
    integrateConversationsIcon,
  } = useStaticQuery(
    graphql`
      query {
        attendCustomersIcon: file(
          relativePath: { eq: "icons/attendCustomersIcon@3x.png" }
        ) {
          childImageSharp {
            fixed(width: 65, height: 65) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        automateUpIcon: file(
          relativePath: { eq: "icons/automateUpIcon@3x.png" }
        ) {
          childImageSharp {
            fixed(width: 65, height: 65) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        buildChatbotIcon: file(
          relativePath: { eq: "icons/buildChatbotIcon@3x.png" }
        ) {
          childImageSharp {
            fixed(width: 65, height: 65) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        easilyDeployIcon: file(
          relativePath: { eq: "icons/easilyDeployIcon@3x.png" }
        ) {
          childImageSharp {
            fixed(width: 65, height: 65) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        integrateAIIcon: file(
          relativePath: { eq: "icons/integrateAIIcon@3x.png" }
        ) {
          childImageSharp {
            fixed(width: 65, height: 65) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        integrateConversationsIcon: file(
          relativePath: { eq: "icons/integrateConversationsIcon@3x.png" }
        ) {
          childImageSharp {
            fixed(width: 65, height: 65) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  let items = [
    { copy: automation_bullet_point_1.description, icon: buildChatbotIcon },
    {
      copy: automation_bullet_point_2.description,
      icon: integrateAIIcon,
    },
    {
      copy: automation_bullet_point_3.description,
      icon: integrateConversationsIcon,
    },
    {
      copy: automation_bullet_point_4.description,
      icon: attendCustomersIcon,
    },
    {
      copy: automation_bullet_point_5.description,
      icon: easilyDeployIcon,
    },
    {
      copy: automation_bullet_point_6.description,
      icon: automateUpIcon,
    },
  ]
  return (
    <Section px={[15, 0]} py={[70, 90]} background={<Bg />}>
      <Flex flexDirection="column">
        <Flex width={[1, 1 / 2]}>
          <Title>{automation_title}</Title>
        </Flex>
        <Flex flexWrap="wrap">
          {items.map((e, i) => {
            return (
              <Flex key={i} my={[30, 15]} width={[1, '50%']}>
                <Flex justifyContent="center">
                  <Flex mr={15}>
                    <Img fixed={e.icon.childImageSharp.fixed} />
                  </Flex>
                  <Flex mt={[20, 20]}>
                    <BulletPointsText>{e.copy}</BulletPointsText>
                  </Flex>
                </Flex>
              </Flex>
            )
          })}
        </Flex>
      </Flex>
    </Section>
  )
}

export default AutomationChatbots
